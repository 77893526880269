<template>
  <div class="flex flex-1">
    <portal to="popup-inser-email">
      <PopupInsertEmail
        v-if="showModal"
        @emailSaved="sendAlert"
        @closeModal="showModal = false"
      ></PopupInsertEmail>
    </portal>
    <LayoutMapAndTools :withToolBar="zones_urbanisme.length !== 0">
      <template #outils>
        <OutilsUrba
          v-if="zones_urbanisme.length"
          :zones_urbanisme="zones_urbanisme"
          :libelles_zones_urbanisme="libelles_zones_urbanisme"
          @showDocsUrbanisme="$refs.map_urbanisme.showModal = true"
        ></OutilsUrba>
      </template>

      <template #footer-details-infos>
        <EmptyStateOnMap
          title="Urbanisme"
          name_icon="urbanisme"
          v-if="!zones_urbanisme.length"
        >
          <template #message-error>
            <span class="text-promy-gray-250"
              >Informations d'urbanisme <br />
              non disponibles.</span
            >
          </template>
          <template #body>
            <div class="flex flex-col pt-4">
              <p class="text-sm text-promy-green-350 italic mb-3">
                Vous pouvez rechercher les documents manuellement <br />
                en cliquant sur le bouton Recherchez
              </p>
              <div
                class="flex space-x-6 sm:space-x-0 sm:flex-col sm:space-y-2 sm:pt-8"
              >
                <ProButton
                  class="h-[60px] space-x-1 w-[9rem] sm:w-full"
                  @click="RechercherZoneUrbanisme"
                >
                  <span> Recherchez </span>
                </ProButton>
                <ProButton
                  class="h-[60px] space-x-1 w-[9rem] sm:w-full"
                  @click="sendAlert"
                  btn="secondary"
                >
                  <span> Alertez-nous </span>
                </ProButton>
              </div>
            </div>
          </template>
        </EmptyStateOnMap>
      </template>
      <template #layers>
        <MapType :layers="layers" />
      </template>
      <template #map>
        <Map
          :zones_urbanisme="zones_urbanisme"
          :libelles_zones_urbanisme="libelles_zones_urbanisme"
          ref="map_urbanisme"
        ></Map>
      </template>
    </LayoutMapAndTools>
  </div>
</template>

<script>
import Mapbox from '@/mixins/map/mapbox'
import LayoutMapAndTools from '../LayoutMapAndTools.vue'
import Map from './map.vue'
import OutilsUrba from './OutilsUrba.vue'
import { mapGetters } from 'vuex'
import helpers from '@/mixins/helpers'
export default {
  components: {
    LayoutMapAndTools,
    Map,
    OutilsUrba,
  },
  data() {
    return {
      toggle_actions: true,
      layers: [],
      zones_urbanisme: [],
      libelles_zones_urbanisme: [],
      showModal: false,
    }
  },
  mixins: [Mapbox, helpers],
  computed: {
    ...mapGetters({
      features_autour: 'parcelle/features_autour',
      parcelles_autour: 'parcelle/parcelles_autour',
      current_parcelle: 'parcelle/current_parcelle',
      parcelles_voisines: 'parcelle/parcelles_voisines',
      map: 'map/map',
      address: 'address/address',
      user: 'auth/user',
      infos_urbanisme: 'address/infos_urbanisme',
      infos_commune: 'address/infos_commune',
    }),
  },
  created() {
    this.layers = [
      {
        label: 'Cadastre',
        image: 'Cadastre.svg',
        is_active: false,
        component: 'CadastreOfficiel',
        data: {
          code_insee: this.infos_commune.code_insee,
        },
      },
    ]
    this.init()
  },
  methods: {
    RechercherZoneUrbanisme() {
      window.open(this.url_google, '_blank')
    },
    sendAlert() {
      if (!this.user.email) {
        this.showModal = true
        return
      }
      const api_url = `grand-publics/adresses/${this.address.uuid}/notify-urbanisme`
      this.$http.post(api_url).then(
        (response) => {
          this.showModal = false
          this.$toaster.success(response.data)
        },
        () => {
          this.$toaster.warning('Une erreur est survenue. Veuillez réessayer.')
        },
      )
    },

    init() {
      let zones = {
        features: this.infos_urbanisme.data.urbanisme_parcelles
          .map((item) => item?.zone_urba?.features)
          .filter((el) => el)
          .flat(),
        type: 'FeatureCollection',
      }
      this.zones_urbanisme = this.removeDuplicateFeature(zones.features)
      this.url_google =
        process.env.VUE_APP_GOOGLE_SEARCH +
        'Règlement Urbanisme ' +
        this.address.information.properties.city
      if (this.zones_urbanisme) {
        this.libelles_zones_urbanisme = this.getListZonesUrba(
          this.zones_urbanisme,
        )
      }
    },

    getListZonesUrba(zones_urba) {
      let list_zones_urba = []
      zones_urba.forEach((zone_urba, index) => {
        let libelle = this.getNestedObject(zone_urba, 'properties', 'LIBELLE')
        let libelong = this.getNestedObject(zone_urba, 'properties', 'LIBELONG')

        list_zones_urba[index] = this.isExist(libelle) ? libelle + ' ' : ''
        list_zones_urba[index] += this.isExist(libelong) ? libelong : ''
        if (!list_zones_urba[index].length) {
          list_zones_urba[index] = 'valeur indisponible'
        }
      })
      return list_zones_urba
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$destroy()
    next()
  },
}
</script>
