<template>
  <div>
    <div class="flex flex-col space-y-6">
      <div class="flex flex-col space-y-4">
        <div class="flex flex-col">
          <span class="text-promy-green-350 font-bold mb-2"
            >Zonage de(s) parcelle(s) sélectionnée(s) :</span
          >
          <p
            class="text-promy-gray-180 mb-2"
            v-for="(zone, index) in libelles_zones_urbanisme"
            :key="index"
          >
            - {{ zone }}
          </p>
        </div>
      </div>

      <ProButton
        class="h-[60px] space-x-1 w-[17rem]"
        @click="$emit('showDocsUrbanisme')"
      >
        <span> Document d’urbanisme</span>
        <ProIcon
          name="download"
          color="white"
          class="!w-[20px] !h-[13.85px]"
        ></ProIcon>
      </ProButton>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      toggle_actions: true,

      openToolBar: false,
    }
  },
  props: {
    libelles_zones_urbanisme: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      current_batiments: 'parcelle/current_batiments',
    }),
  },
  methods: {
    toggle() {
      this.openToolBar = !this.openToolBar
    },
    closeToolBar() {
      this.openToolBar = false
    },
  },
}
</script>
